<template>
    <div class="form-container">
        <sui-form>
            <div class="html2pdf__page-break">
                <section class="attestation-header">
                    <FormHeader :logo="logo" :firm-name="firm.name" />
                </section>
                <section class="attestation-intro">
                    <p>
                        {{firm.name}} considers all its employees to be "Supervised Persons"
                        subject to the firm's Compliance Policies & Procedures Manual ("Manual") and Code of
                        Ethics. Supervised Persons are required to attest that they have read and will abide by
                        the Manual and Code of Ethics. In addition to that annual attestation, {{firm.name}}
                        requires that you affirm the following and submit the indicated information to the
                        CCO for {{firm.name}} quarterly:
                    </p>
                </section>
                <section class="contact-information">
                    <sui-form-fields equal-width>
                        <sui-form-field required>
                            <label>First Name</label>
                            <input type="text"  name="first-name" placeholder="First Name" v-model.trim="attestation.First_Name__c"  v-bind:readonly="isReadonly"/>
                        </sui-form-field>
                        <sui-form-field>
                            <label>Middle Name</label>
                            <input type="text" name="middle-name" placeholder="Middle Name" v-model.trim="attestation.Middle_Name__c" v-bind:readonly="isReadonly"/>
                        </sui-form-field>
                        <sui-form-field required>
                            <label>Last Name</label>
                            <input type="text" name="last-name" placeholder="Last Name" v-model.trim="attestation.Last_Name__c" v-bind:readonly="isReadonly"/>
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-form-fields equal-width>
                        <sui-form-field>
                            <label>Job Title</label>
                            <input type="text" name="job-title" placeholder="Job Title" v-model="attestation.Job_Title__c" v-bind:readonly="isReadonly"/>
                        </sui-form-field>
                        <sui-form-field required>
                            <label>Employment Date</label>
                            <input type="text" name="date-of-employment" placeholder="MM/DD/YYYY" v-model="attestation.Employment_Date__c" v-bind:readonly="isReadonly"/>
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-form-fields equal-width>
                        <sui-form-field>
                            <label>Work Phone</label>
                            <input type="text" name="work-phone" placeholder="Work Phone" v-model="attestation.Work_Phone__c" v-bind:readonly="isReadonly"/>
                        </sui-form-field>
                        <sui-form-field>
                            <label>Home Phone</label>
                            <input type="text" name="home-phone" placeholder="Home Phone" v-model="attestation.Home_Phone__c" v-bind:readonly="isReadonly"/>
                        </sui-form-field>
                        <sui-form-field>
                            <label>Mobile Phone</label>
                            <input type="text" name="mobile-phone" placeholder="Mobile Phone" v-model="attestation.Mobile_Phone__c" v-bind:readonly="isReadonly"/>
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-form-fields equal-width>
                        <sui-form-field>
                            <label>Work Email</label>
                            <input type="text" name="work-email" placeholder="Work Email" v-model="attestation.Work_Email__c" v-bind:readonly="isReadonly"/>
                        </sui-form-field>
                        <sui-form-field>
                            <label>Personal Email</label>
                            <input type="text" name="personal-email" placeholder="Personal Email" v-model="attestation.Personal_Email__c" v-bind:readonly="isReadonly"/>
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-form-field>
                        <label>Home Address</label>
                        <sui-form-fields equal-width>
                            <sui-form-field>
                                <input type="text" name="home[address-line-1]" placeholder="Address Line 1" v-model="attestation.Address_Line_1__c" v-bind:readonly="isReadonly"/>
                            </sui-form-field>
                            <sui-form-field>
                                <input type="text" name="home[address-line-2]" placeholder="Address Line 2 (optional)" v-model="attestation.Address_Line_2__c" v-bind:readonly="isReadonly"/>
                            </sui-form-field>
                        </sui-form-fields>
                    </sui-form-field>
                    <sui-form-fields>
                        <sui-form-field width="six">
                            <label>City</label>
                            <input type="text" name="home[city]" placeholder="City" v-model="attestation.City__c" v-bind:readonly="isReadonly"/>
                        </sui-form-field>
                        <sui-form-field width="six">
                            <label>State</label>
                            <sui-dropdown
                                placeholder="State"
                                selection
                                search
                                direction="downward"
                                :options="states"
                                v-model="attestation.State__c"
                            />
                        </sui-form-field>
                        <sui-form-field width="four">
                            <label>Zip Code</label>
                            <input type="text" name="home[zip-code]" placeholder="Zip Code" v-model="attestation.Zip_Code__c" v-bind:readonly="isReadonly"/>
                        </sui-form-field>
                    </sui-form-fields>
                </section>
            </div>
            <div class="html2pdf__page-break">
                <section class="investment-advisory-info-section">
                    <h3 is="sui-header">Investment Advisory Information</h3>
                    <div>
                        <sui-grid>
                            <sui-grid-row columns="two">
                                <sui-grid-column :width="8">
                                    <sui-form-fields equal-width>
                                        <sui-form-field>
                                            <label>CRD Number</label>
                                            <input type="text" name="crd-number" placeholder="CRD Number" v-model="attestation.CRD_Number__c" v-bind:readonly="isReadonly"/>
                                        </sui-form-field>
                                        <sui-form-field>
                                            <label>Insurance License</label>
                                            <input type="text" name="insurance-license" placeholder="Insurance License" v-model="attestation.Insurance_License__c" v-bind:readonly="isReadonly">
                                        </sui-form-field>
                                    </sui-form-fields>
                                    <sui-form-field>
                                        <label>Broker-Dealer</label>
                                        <input type="text" name="broker-dealer" placeholder="Broker-Dealer" v-bind:readonly="isReadonly">
                                    </sui-form-field>
                                </sui-grid-column>
                                <sui-grid-column :width="8">
                                    <sui-form-field>
                                        <label>States Registered</label>
                                        <sui-dropdown
                                            fluid
                                            multiple
                                            selection
                                            placeholder="States Registered"
                                            direction="downward"
                                            :options="states"
                                            v-model="attestation.States_Registered__c"
                                        />
                                    </sui-form-field>
                                    <sui-form-field>
                                        <label>States Where Clients Reside</label>
                                        <sui-dropdown
                                            fluid
                                            multiple
                                            selection
                                            direction="downward"
                                            placeholder="States Where Clients Reside"
                                            :options="states"
                                            v-model="attestation.States_Clients_Reside__c"
                                        />
                                    </sui-form-field>
                                </sui-grid-column>
                            </sui-grid-row>
                        </sui-grid>
                        <h4 is="sui-header">Qualifications and Certifications</h4>
                        <div class="qualifications-and-certifications">
                            <sui-grid>
                                <sui-grid-row columns="two">
                                    <sui-grid-column :width="8" >
                                        <div class="certifications">
                                            <sui-checkbox class="cert" label="CFP" name="cfp" v-model="attestation.CFP__c" v-bind:disabled="isReadonly" />
                                            <sui-checkbox class="cert" label="ChFC" type="checkbox" name="chfc"  v-model="attestation.ChFC__c" v-bind:disabled="isReadonly"/>
                                            <sui-checkbox class="cert" label="CFA" name="cfa" v-model="attestation.CFA__c" v-bind:disabled="isReadonly"/>
                                            <sui-checkbox class="cert" label="PFS" name="pfs" v-model="attestation.PFS__c" v-bind:disabled="isReadonly"/>
                                            <sui-checkbox class="cert" label="CIC" name="cic" v-model="attestation.CIC__c" v-bind:disabled="isDisabled"/>
                                            <sui-checkbox class="cert" label="CKA" name="cka" v-model="attestation.CKA__c" v-bind:disabled="isDisabled"/>
                                            <sui-checkbox class="cert" label="Series 7" name="series7" v-model="attestation.Series_7__c" v-bind:disabled="isDisabled"/>
                                            <sui-checkbox class="cert" label="Series 24" name="series24" v-model="attestation.Series_24__c" v-bind:disabled="isDisabled"/>
                                            <sui-checkbox class="cert" label="Series 65" id="series65" name="series65" v-model="attestation.Series_65__c" v-bind:disabled="isDisabled"/>
                                            <sui-checkbox class="cert" label="Series 66" name="series66" v-model="attestation.Series_66__c" v-bind:disabled="isDisabled"/>
                                        </div>
                                    </sui-grid-column>
                                    <sui-grid-column :width="8">
                                        <sui-form-field>
                                            <label>Other Qualifications</label>
                                            <textarea
                                                rows="3"
                                                wrap="soft"
                                                v-model="attestation.Other_Qualifications__c"
                                                v-bind:readonly="isReadonly"
                                            />
                                        </sui-form-field>
                                    </sui-grid-column>
                                </sui-grid-row>
                            </sui-grid>
                            <div class="field">
                                <label>Solicitor Referral Relationships</label>
                                <textarea
                                    rows="2"
                                    wrap="soft"
                                    v-model="attestation.Solicitor_Referral_Relationships__c"
                                    v-bind:readonly="isReadonly"
                                >
                                </textarea>
                            </div>
                            <div class="field">
                                <label>Non-Advisory Compensation Arrangements</label>
                                <textarea
                                    rows="2"
                                    wrap="soft"
                                    v-model="attestation.Non_Advisory_Compensation_Arrangements__c"
                                    v-bind:readonly="isReadonly">
                                </textarea>
                            </div>
                            <div class="inline fields">
                                <label>Are you a covered associated?<span class="definition-guide"> (see definiton below)</span></label>
                                <div class="field">
                                    <div class="ui checkbox">
                                        <input type="radio" value="true" name="is-covered-associate" v-model="attestation.Is_Covered_Associate__c" v-bind:disabled="isReadonly"/>
                                        <label>Yes</label>
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="ui checkbox">
                                        <input type="radio" value="false" name="is-covered-associate" v-model="attestation.Is_Covered_Associate__c" v-bind:disabled="isReadonly"/>
                                        <label>No</label>
                                    </div>
                                </div>
                            </div>
                            <p class="definition">
                                <span class="definition-intro">Definition: </span>
                                <span class="definition-content">
                                    General partner, managing member or executing office, or other individual with a similar
                                    status or function; any employee who solicits a government entity for the investment adviser and any person who
                                    supervises, directly or indirectly, such employee; and any PAC controlled by the investment advisor or by another
                                    covered associate.
                                </span>
                            </p>
                            <div class="inline fields">
                                <label>Are you an access person?<spanc class="definition-guide"> (see definition below)</spanc></label>
                                <div class="field">
                                    <div class="ui checkbox">
                                        <input type="radio" value="true" name="is-access-person" v-model="attestation.Is_Access_Person__c" v-bind:disabled="isReadonly"/>
                                        <label>Yes</label>
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="ui checkbox">
                                        <input type="radio"  value="false" name="is-access-person" v-model="attestation.Is_Access_Person__c" v-bind:disabled="isReadonly"/>
                                        <label>No</label>
                                    </div>
                                </div>
                            </div>
                            <p class="definition">
                                <span class="definition-intro">Definition: </span>
                                <span class="definition-content">
                                    any supervised person who has access to nonpublic information regarding any clients’
                                    purchase or sale of securities or is involved in making securities recommendations.
                                </span>
                            </p>
                        </div>
                    </div>
                </section>
                <section class="social-media-section">
                    <h3 is="sui-header">Social Media for Business Purposes</h3>
                    <sui-grid>
                        <sui-grid-row columns="two">
                            <sui-grid-column>
                                <div>
                                    <sui-checkbox class="social-media" label="LinkedIn" name="linkedIn" v-model="attestation.LinkedIn__c" v-bind:disabled="isReadonly"/>
                                    <sui-checkbox class="social-media" label="Facebook" name="facebook" v-model="attestation.Facebook__c" v-bind:disabled="isReadonly"/>
                                    <sui-checkbox class="social-media" label="X (formerly Twitter)" name="x" v-model="attestation.Twitter__c" v-bind:disabled="isReadonly"/>
                                    <sui-checkbox class="social-media" label="YouTube" name="youtube" v-model="attestation.YouTube__c" v-bind:disabled="isReadonly"/>
                                </div>
                            </sui-grid-column>
                            <sui-grid-column>
                                <sui-form-field>
                                    <label>Other Social Media</label>
                                    <textarea
                                        rows="3"
                                        wrap="soft"
                                        v-model="attestation.Other_Social_Media__c" v-bind:readonly="isReadonly">
                                    </textarea>
                                </sui-form-field>
                            </sui-grid-column>
                        </sui-grid-row>
                    </sui-grid>
                </section>
            </div>
            <div class="html2pdf__page-break">
                <section v-if="supervisedPerson && supervisedPerson.has_to_submit_personal_securities" class="personal-security-section" >
                    <h3 is="sui-header">Personal Securities</h3>
                    <div>
                        <label>
                          <font-awesome-icon icon="check" /> I have reported all personal securities transactions for the last 12 months (or directed the account custodian to send my quarterly
                          statements directly to the CCO). I consent to the firm’s compliance staff directly accessing my personal investment
                          statements through the custodian for all of my investment accounts being managed by the firm. If my 401(k) or similar account has a
                          self-directed account feature, I have not activated that feature or otherwise have the ability to trade in “Reportable Securities” (as that
                          term is defined in the Company’s Code of Ethics) in that account. The custodial accounts over which I have direct or indirect influence
                          or control (except for 401(k), 403(b) and 457 accounts which are unable to hold Reportable Securities)
                          are as follows (list account owner, account type, custodian, & account number):
                        </label>
                    </div>
                    <PersonalSecurityTable
                        :personalSecurities="personalSecurities"
                        :toggleIsNewPersonalSecurity="toggleIsNewPersonalSecurity"
                        :reset="resetPersonalSecurityForm"
                        @handlePersonalSecurityForm="handlePersonalSecurityForm"
                        @getPersonalSecurity="getPersonalSecurity"
                        @removePersonalSecurity="removePersonalSecurity"
                    />
                </section>
            </div>
            <section
                v-if="supervisedPerson && supervisedPerson.has_to_submit_outside_business_activities"
                class="outside-business-activity-section"
            >
                <h3 is="sui-header">Outside Business Activities</h3>
                <div>
                    <p>My outside business activities, including service on any board, are as follows:</p>
                </div>
                <OutsideBusinessActivityTable
                    :outsideBusinessActivities="outsideBusinessActivities"
                    :toggleIsNewOutsideBusinessActivity="toggleIsNewOutsideBusinessActivity"
                    :reset="resetOutsideBusinessActivityForm"
                    @handleOutsideBusinessActivityForm="handleOutsidBusinessActivityForm"
                    @getOutsideBusinessActivity="getOutsideBusinessActivity"
                    @removeOutsideBusinessActivity="removeOutsideBusinessActity"
                />
                <sui-form-field>
                    <label>
                        My business, financial or personal relationships which could give me access to material, non-public information (regarding a publicly-traded security which would affect the market value of trade security) are as follows:
                    </label>
                    <textarea rows="2" wrap="soft" v-model="attestation.BusinessFinancialOrPersonalRelationships__c"></textarea>
                </sui-form-field>
                <sui-form-field>
                  <label>
                    I confirm I do not serve as a trustee, co-trustee, power of attorney, executor, or equivalent for a client of the firm, except as follows (if applicable):
                  </label>
                  <textarea rows="2" wrap="soft" v-model="attestation.ServesAs__c"></textarea>
                </sui-form-field>
            </section>
            <section
                v-if="supervisedPerson && supervisedPerson.has_to_submit_political_contributions"
                class="political-contribution-section"
            >
                <h3 is="sui-header">Political Contributions</h3>
                <div>
                    <p>My political contributions for the previous 12 months are as follows:</p>
                </div>
                <PoliticalContributionTable
                    :politicalContributions="politicalContributions"
                    :isNewPoliticalContribution="toggleIsNewPoliticalContribution"
                    :toggleIsNewPoliticalContribution="toggleIsNewPoliticalContribution"
                    :reset="resetPoliticalContributionForm"
                    @handlePoliticalContributionForm="handlePoliticalContributionForm"
                    @getPoliticalContribution="getPoliticalContribution"
                    @removePoliticalContribution="removePoliticalContribution"
                />
            </section>
            <section class="attestation-manuals">
              <sui-form-field>
                <label>Manual and Code of Ethics Attestation Date</label>
                <input
                  type="date"
                  placeholder="Manual and Code of Ethics attestation date"
                  v-model="attestation.Code_of_Ethics_Attestation_Date__c"
                />
              </sui-form-field>
            </section>
            <section class="confirmation-section">
                <p>
                  By submitting this Quarterly Attestation, I am attesting that, to the best of my knowledge, I have
                  been and will remain in compliance with the Manual and Code of Ethics.
                </p>
                <p>I further attest that:</p>
                <div class="field" style="margin-top: 20px;">
                    <div>
                        <label>
                          <font-awesome-icon icon="check" /> I have not given or received within the last 12 months any gift, entertainment, or gratuity
                          from any person or entity with whom we do business with a value in excess of the amount set
                          forth in the the Code of Ethics without obtaining the approval of the CCO.
                        </label>
                    </div>
                </div>
                <div class="field">
                    <div>
                        <label>
                            <font-awesome-icon icon="check" /> I have not used and will not use my cell phone or other personal device for business purposes
                            (excluding phone calls) unless that phone or device is password protected
                        </label>
                    </div>
                </div>
                <div class="field">
                    <div>
                        <label>
                            <font-awesome-icon icon="check" /> I have read and am in compliance with {{firm.name}}’s policies on social media,
                            email and other electronic communications. Specifically, I have: (1) not used personal
                            or any other non-{{firm.name}} email addresses, instant messaging, personal social networks or
                            other electronic means for firm or client communications except in accordance with the
                            firm’s policies regarding advertising and retention of written communications; (2)
                            saved copies of any text messages required to be saved received from clients and
                            encouraged them to communicate instead by phone, email or in person; (3) not used social
                            media, websites or blogs to promote {{firm.name}} without the approval of {{firm.name}}’s compliance officer.
                          
                            I have read and am in compliance with {{firm.name}}’s policies on social media, email and other
                            electronic communications. Specifically, I have: (1) not used personal or any other non-{{firm.name}}
                            email addresses, instant messaging, personal social networks or other electronic means for firm
                            or client communications except in accordance with the firm’s policies regarding advertising and
                            retention of written communications; (2) not sent business related text to clients outside of the
                            texting method approved by the CCO; (3) saved copies of any business related text messages
                            received from clients outside of the texting method approved by the CCO and encouraged them
                            to only send business related texts via the texting method approved by the CCO, or
                            communicate instead by phone, email or in person; (4) not used social media, websites or blogs
                            to promote {{firm.name}} without the prior approval of {{firm.name}}’s CCO.
                        </label>
                    </div>
                </div>
                <p style="font-style: italic">If you are unable to confirm any attestation listed above, please contact the CCO.</p>
            </section>
            <section class="other-info-section" v-if="this.$route.name !== 'attestationForm'">
                <div v-if="this.$store.getters.userProfile.profile.is_cco || this.$store.getters.userProfile.profile.is_super_cco">
                    <sui-form-fields equal-width>
                        <sui-form-field >
                            <label>Status</label>
                            <sui-dropdown
                                placeholder="Status"
                                selection
                                :options="attestationsStatusOptions"
                                v-model="attestation.Status__c"
                                v-bind:disabled="isDisabled"
                            />
                        </sui-form-field>
                        <sui-form-field>
                            <label>Reviewed Date</label>
                            <input
                                type="date"
                                placeholder="Reviewed Date"
                                v-model="attestation.Reviewed_Date__c"
                            />
                        </sui-form-field>
                    </sui-form-fields>
                </div>
            </section>
            <PersonalSecurityForm
                ref="personalSecurityForm"
                :isOpen="togglePersonalSecurityForm"
                :isNew="isNewPersonalSecurity"
                :index="index"
                :security="personalSecurity"
                :toggleIsNewPersonalSecurity="toggleIsNewPersonalSecurity"
                @handlePersonalSecurityForm="handlePersonalSecurityForm"
                @addPersonalSecurity="addPersonalSecurity"
                @editPersonalSecurity="editPersonalSecurity"
            />
            <OutsideBusinessActivityForm
                ref="outsideBusinessActivityForm"
                :isOpen="toggleOutsideBusinessActivityForm"
                :isNew="isNewOusideBusinessAcvity"
                :index="index"
                :business-activity="outsideBusinessActivity"
                :toggleIsNewOutsideBusinessActivity="toggleIsNewOutsideBusinessActivity"
                @handleOutsideBusinessActivityForm="handleOutsidBusinessActivityForm"
                @addOutsideBusinessActivity="addOutsideBusinessActivity"
                @editOutsideBusinessActivity="editOusideBusinessActivity"
            />
            <PoliticalContributionForm
                ref="politicalContributionForm"
                :isOpen="togglePoliticalContributionForm"
                :isNew="isNewPoliticalContribution"
                :index="index"
                :political-contribution="politicalContribution"
                :toggleIsNewPoliticalContribution="toggleIsNewPoliticalContribution"
                @handlePoliticalContributionForm="handlePoliticalContributionForm"
                @addPoliticalContribution="addPoliticalContribution"
                @editPoliticalContribution="editPoliticalContribution"
            />
        </sui-form>
    </div>
</template>

<script>
    import FormHeader from "@/components/compliance/AttestationManager/Forms/FormHeader";
    import PersonalSecurityTable from "../PersonalSecurityTable";
    import OutsideBusinessActivityTable from "../OutsideBusinessActivityTable";
    import PoliticalContributionTable from "../PoliticalContributionTable";
    import PersonalSecurityForm from "../Forms/PersonalSecurityForm";
    import OutsideBusinessActivityForm from "../Forms/OutsideBusinessActivityForm";
    import PoliticalContributionForm from "../Forms/PoliticalContributionForm";
    import months from "@/mixins/months";
    import states from "@/mixins/states";
    import fiscalQuarter from "@/mixins/fiscalQuarter";
    export default {
        name: "MasterForm",
        components: {
            FormHeader,
            PoliticalContributionTable,
            OutsideBusinessActivityTable,
            PersonalSecurityTable,
            PersonalSecurityForm,
            OutsideBusinessActivityForm,
            PoliticalContributionForm,
        },
        props: {
            isNewAttestation: {type: Boolean, default: false},
            isReadonly: {type: Boolean, default: false},
            isDisabled: {type: Boolean, default: false},
            firm: {type: Object, default: () => ({
                    name: null,
                    abbreviation: null,
                })
            },
            attestation: {type: Object},
            getAttestaions: {type: Function},
        },
        mixins: [months, states, fiscalQuarter],
        inject: ['notyf'],
        data() {
            return {
                firmLogos: [
                    {firm: "ESWA", firmId: "1", logo: require("@/assets/logos/quarterly-attestation/ESWA.png")},
                    {firm: "BWWS", firmId: "2", logo: require("@/assets/logos/quarterly-attestation/BWWS.png")},
                    {firm: "HIM", firmId: "3", logo: require("@/assets/logos/quarterly-attestation/HIM.png")},
                    {firm: "CCM", firmId: "4", logo: require("@/assets/logos/quarterly-attestation/CCM.png")},
                ],
                personalSecurity: {
                    Account_Owner__c: null,
                    Account_Number__c: null,
                    Account_Type__c: null,
                    Custodian__c: null,
                },
                outsideBusinessActivity: {
                    Name: null,
                    Approved_By__c: null,
                    Approved_Date__c: null,
                },
                politicalContribution: {
                    Amount__c: null,
                    Candidate_Name__c: null,
                    Political_Race__c: null,
                    Election_Date__c: null,
                    Approved_By__c: null,
                    Approved_Date__c: null,
                },
                attestationsStatusOptions: [
                    {key: 'submitted', value: 'Submitted', text: 'Submitted'},
                    {key: 'reviewed', value: 'Reviewed', text: 'Reviewed'},
                ],
                togglePersonalSecurityForm: false,
                toggleOutsideBusinessActivityForm: false,
                togglePoliticalContributionForm: false,
                index: null,
                isNewPersonalSecurity: false,
                isNewOusideBusinessAcvity: false,
                isNewPoliticalContribution: false,
            }
        },
        computed: {
            personalSecurities() {
                return this.$store.getters.personalSecurities;
            },
            outsideBusinessActivities() {
                return this.$store.getters.outsideBusinessActivities;
            },
            politicalContributions() {
                return this.$store.getters.politicalContributions;
            },
            supervisedPerson() {
                return this.$store.getters.supervisedPerson;
            },
            logo() {
                const person = this.$store.state.supervisedPerson;
                let logoFilePath = null;
                for (let item of this.firmLogos) {
                    if (item.firmId == person.firm_id) {
                        logoFilePath = item.logo;
                        break;
                    }
                }
                return logoFilePath;
            },
           amount() {
              if(this.firm.name === "Wealth Management Solutions") {
                return "$100.00";
              } else if(this.firm.name === "Christy Capital Management, Inc.") {
                return "$200.00";
              } else {
                return "$350.00";
              }
           }
        },
        methods: {
            toggleIsNewPersonalSecurity() {
                this.isNewPersonalSecurity = !this.isNewPersonalSecurity;
            },
            toggleIsNewOutsideBusinessActivity() {
                this.isNewOusideBusinessAcvity = !this.isNewOusideBusinessAcvity;
            },
            toggleIsNewPoliticalContribution() {
                this.isNewPoliticalContribution = !this.isNewPoliticalContribution;
            },
            // Show or hide personal security form.
            handlePersonalSecurityForm() {
                this.togglePersonalSecurityForm = !this.togglePersonalSecurityForm;
            },
            // Show or hide outside business activity form.
            handleOutsidBusinessActivityForm() {
                this.toggleOutsideBusinessActivityForm = !this.toggleOutsideBusinessActivityForm;
            },
            // Show or hide political contribution form.
            handlePoliticalContributionForm() {
                this.togglePoliticalContributionForm = !this.togglePoliticalContributionForm;
            },
            getPersonalSecurity(index) {
                this.handlePersonalSecurityForm();
                this.index = index;
                this.personalSecurity = JSON.parse(JSON.stringify(this.$store.getters.personalSecurities[index]));
            },
            getOutsideBusinessActivity(index) {
                this.handleOutsidBusinessActivityForm();
                this.index = index;
                this.outsideBusinessActivity = JSON.parse(JSON.stringify(this.$store.getters.outsideBusinessActivities[index]));
            },
            getPoliticalContribution(index) {
                this.handlePoliticalContributionForm();
                this.index = index;
                this.politicalContribution = JSON.parse(JSON.stringify(this.$store.getters.politicalContributions[index]));
            },
            addPersonalSecurity(personalSecurity) {
                let securities = JSON.parse(JSON.stringify(this.$store.getters.personalSecurities));
                securities.push(personalSecurity);
                this.$store.commit('setPersonalSecurities', JSON.parse(JSON.stringify(securities)));
                this.toggleIsNewPersonalSecurity();
                this.handlePersonalSecurityForm();
            },
            addOutsideBusinessActivity(ousideBusinessActivity) {
                let businessActivities = JSON.parse(JSON.stringify(this.$store.getters.outsideBusinessActivities));
                businessActivities.push(ousideBusinessActivity);
                this.$store.commit('setOutsideBusinessActivities', JSON.parse(JSON.stringify(businessActivities)));
                this.toggleIsNewOutsideBusinessActivity();
                this.handleOutsidBusinessActivityForm();
            },
            addPoliticalContribution(politicalContribution) {
                let politicalContributions = JSON.parse(JSON.stringify(this.$store.getters.politicalContributions));
                politicalContributions.push(politicalContribution);
                this.$store.commit('setPoliticalContributions', JSON.parse(JSON.stringify(politicalContributions)));
                this.toggleIsNewPoliticalContribution();
                this.handlePoliticalContributionForm();
            },
            editPersonalSecurity(security) {
                let securities = this.$store.getters.personalSecurities;
                securities[security.index] = security.data;
                this.$store.commit('setPersonalSecurities', JSON.parse(JSON.stringify(securities)));
                this.handlePersonalSecurityForm();
            },
            editOusideBusinessActivity(businessActivity) {
                let businessActivities = this.$store.getters.outsideBusinessActivities;
                businessActivities[businessActivity.index] = businessActivity.data;
                this.$store.commit('setOutsideBusinessActivities', JSON.parse(JSON.stringify(businessActivities)));
                this.handleOutsidBusinessActivityForm();
            },
            editPoliticalContribution(politicalContribution) {
                let politicalContributions = this.$store.getters.politicalContributions;
                politicalContributions[politicalContribution.index] = politicalContribution.data;
                this.$store.commit('setPoliticalContributions', JSON.parse(JSON.stringify(politicalContributions)));
                this.handlePoliticalContributionForm();
            },
            removePersonalSecurity(data) {
                if (this.isNewAttestation) {
                    this.personalSecurities.splice(data.index, 1);
                } else {
                    this.personalSecurities.splice(data.index, 1);
                    if (data.id) this.$store.dispatch('deletePersonalSecurity', data.id);
                }
            },
            removeOutsideBusinessActity(data) {
                if (this.isNewAttestation) {
                    this.outsideBusinessActivities.splice(data.index, 1);
                } else {
                    this.outsideBusinessActivities.splice(data.index, 1);
                    if (data.id) this.$store.dispatch('deleteOutsideBusinessActivity', data.id);
                }
                
            },
            removePoliticalContribution(data) {
                if (this.isNewAttestation) {
                    this.politicalContributions.splice(data.index, 1);
                } else {
                    this.politicalContributions.splice(data.index, 1);
                    if (data.id) this.$store.dispatch('deletePoliticalContribution', data.id);
                }
            },
            reset(obj) {
                Object.keys(obj).forEach(function (key) {
                    obj[key] = null;
                })
            },
            resetPersonalSecurityForm() {
                this.reset(this.personalSecurity);
            },
            resetOutsideBusinessActivityForm() {
                this.reset(this.outsideBusinessActivity);
            },
            resetPoliticalContributionForm() {
                this.reset(this.politicalContribution);
            },
            getFiscalQuarter(month) {
                /**
                 * ESWA's fiscal year begins in January.
                 *
                 * Quarterly attestations for the last quarter are submitted on the onset of the current fiscal quarter.
                 */
                if (month === this.months.january || month === this.months.february || month === this.months.march) {
                    return 4;
                } else if (month === this.months.april || month === this.months.may || month === this.months.june) {
                    return 1;
                } else if (month === this.months.july || month === this.months.august || month === this.months.september) {
                    return 2;
                } else {
                    return 3;
                }
            },
            getFiscalYear(fiscalQuarter) {
                const currentYear = new Date().getFullYear();
                if (fiscalQuarter === 4) return currentYear - 1;
                return currentYear;
            },
    
            /**
             * Converts a multi-selection to a Salesforce multipicklist value.
             *
             * @param multiselection
             */
            converMultipicklist(multiselection) {
                if (multiselection.length) {
                    return multiselection.toString().replace(new RegExp(',', 'gi'), ';');
                }
                return null;
            },
            getAttestationName() {
                let fname, lname, firmAbbreviation, fiscalYear, fisalQuarter;
                
                fname = this.attestation.First_Name__c ? this.attestation.First_Name__c : '';
                lname = this.attestation.Last_Name__c ? this.attestation.Last_Name__c : '';
    
                this.$store.dispatch('getFirm', this.$store.getters.userProfile.profile._firm);
                firmAbbreviation =  this.$store.getters.firm.abbreviation;
    
                const currentMonth = new Date().getMonth() + 1;
                fisalQuarter = this.getFiscalQuarter(currentMonth);
                fiscalYear = this.getFiscalYear(fisalQuarter);
                
                return `${fname} ${lname} - ${firmAbbreviation} FY${fiscalYear} Q${fisalQuarter}`;
            },
            handleSubmission() {
                let att = JSON.parse(JSON.stringify(this.attestation));
                att.Name = this.getAttestationName();
                
                const fiscalQuarter = this.getFiscalQuarter(new Date().getMonth() +1);
                att.Fiscal_Quarter__c = `Q${fiscalQuarter}`;
                att.Fiscal_Year__c = this.getFiscalYear(fiscalQuarter);
                
                att = Object.assign({}, this.data, {...att,
                    States_Registered__c: this.converMultipicklist(att.States_Registered__c),
                    States_Clients_Reside__c: this.converMultipicklist(att.States_Clients_Reside__c),
                });
                const payload = {
                    quarterlyAttestation: att,
                    personalSecurities: this.$store.getters.personalSecurities,
                    outsideBusinessActivities: this.$store.getters.outsideBusinessActivities,
                    politicalContributions: this.$store.getters.politicalContributions,
                }
                const loader = this.$loading.show();
                this.$store.dispatch('saveQuarterlyAttestation', payload)
                    .then(() => {
                        loader.hide();
                        this.$router.push({name: 'attestationManager'});
                        this.notyf.success('Attestation submitted.');
                    })
                    .catch(() => {
                        this.$router.push({name: 'attestationManager'});
                        loader.hide();
                    });
            },
            handleUpdate() {
                let att = JSON.parse(JSON.stringify(this.attestation));
                att = Object.assign({}, this.data, {...att,
                    States_Registered__c: this.converMultipicklist(att.States_Registered__c),
                    States_Clients_Reside__c: this.converMultipicklist(att.States_Clients_Reside__c),
                });
    
                const payload = {
                    quarterlyAttestation: att,
                    personalSecurities: this.$store.getters.personalSecurities,
                    outsideBusinessActivities: this.$store.getters.outsideBusinessActivities,
                    politicalContributions: this.$store.getters.politicalContributions,
                }
                const loader = this.$loading.show();
                this.$store.dispatch('updateQuarterlyAttestation', payload)
                    .then(() => {
                        this.getAttestaions();
                        loader.hide();
                        this.closeModal();
                        this.notyf.success('Attestation updated successfully.');
                    })
                    .catch(() => {
                        this.closeModal();
                        loader.hide();
                    });
            },
            /**
             * Returns true or false if 15 days have elpased since the start of the current fiscal quarter.
             * @returns {boolean}
             */
            isDelinquent() {
                const timeElapsedInMilliseconds = Math.abs(new Date() - new Date(this.currentFiscalQuarterStartDate()));
                const timeElapsedInDays = timeElapsedInMilliseconds / (1000 * 3600 * 24);
                return (timeElapsedInDays > 15) ? true : false;
            },
            closeModal() {
                this.$emit('closeModal');
            }
        }
    }
</script>

<style scoped>
    .form-container {
        margin-right: 1.5em;
        margin-left: 1.5em;
    }
    .attestation-header {
        text-align: center;
    }
    .attestation-intro {
        margin-top: .5em;
    }
    .contact-information, .investment-advisory-info-section, .social-media-section,
    .personal-security-section, .outside-business-activity-section, .political-contribution-section,
    .confirmation-section {
        margin-top: 2em;
        margin-bottom: 2em;
    }
    .other-info-section {
        margin-top: 2em;
    }
    .certifications {
        display: flex;
        flex-wrap: wrap;
    }
    .certifications .cert {
        margin-top: 15px;
        margin-right: 40px;
    }
    .social-media-section .social-media {
        margin-right: 40px;
        margin-top: 15px;
    }
    .definition-guide, .definition {
        font-style: italic !important;
    }
    .definition .definition-intro {
        font-weight: 600 !important;
    }
</style>
